var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[(_vm.showGeneralResults)?_c('v-row',[_c('v-col',[_c('stock-results',{attrs:{"chart-data":_vm.chartData}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('dashboard-card',{attrs:{"title":"Cantidad stock actual"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"path-to-category":['status', 'status'],"path-to-subcategory":['stock', 'stockDetail', 'acquisitionType'],"data-transformer":_vm.joinStockReserves,"sort-legends":false,"sort-categories":true}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('dashboard-card',{attrs:{"title":"Evolución mensual cantidad stock"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.currentMonthFilters.dailyStock},
                {category: 'prevMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.prevMonthFilters.dailyStock},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: currentMonthFilters.dailyStock},\n                {category: 'prevMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: prevMonthFilters.dailyStock},\n              ])\n            }"}],attrs:{"records":_vm.chartData.currentMonth.dailyStock,"prev-month-records":_vm.chartData.prevMonth.dailyStock,"path-to-category":['processStatus', 'status'],"path-to-date":['reportDate'],"null-zero-values":true,"dates":_vm.dates,"data-transformer":_vm.fixDailyStockDates,"display-means":true,"total-sufix":"prom./día","label-sufix":"prom./día"}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('dashboard-card',{attrs:{"title":"Valor stock actual"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"path-to-category":['status', 'status'],"path-to-subcategory":['stock', 'stockDetail', 'acquisitionType'],"path-to-value":['stock', 'stockDetail', 'totalCost'],"is-currency":true,"data-transformer":_vm.joinStockReserves,"sort-legends":false,"sort-categories":true}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('dashboard-card',{attrs:{"title":"Evolución mensual valor stock"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.currentMonthFilters.dailyStock},
                {category: 'prevMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.prevMonthFilters.dailyStock},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: currentMonthFilters.dailyStock},\n                {category: 'prevMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: prevMonthFilters.dailyStock},\n              ])\n            }"}],attrs:{"records":_vm.chartData.currentMonth.dailyStock,"prev-month-records":_vm.chartData.prevMonth.dailyStock,"path-to-category":['processStatus', 'status'],"path-to-date":['reportDate'],"null-zero-values":true,"dates":_vm.dates,"data-transformer":_vm.fixDailyStockDates,"is-currency":true,"path-to-value":['stock', 'stockDetail', 'totalCost'],"display-means":true,"total-sufix":"prom./día","label-sufix":"prom./día"}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('dashboard-card',{attrs:{"title":"Cantidad stock actual por adquisición"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"path-to-category":['stock', 'stockDetail', 'acquisitionType'],"path-to-subcategory":['status', 'status'],"data-transformer":_vm.joinStockReserves}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('dashboard-card',{attrs:{"title":"Cantidad stock por adquisición"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.currentMonthFilters.dailyStock},
                {category: 'prevMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.prevMonthFilters.dailyStock},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: currentMonthFilters.dailyStock},\n                {category: 'prevMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: prevMonthFilters.dailyStock},\n              ])\n            }"}],attrs:{"records":_vm.chartData.currentMonth.dailyStock,"prev-month-records":_vm.chartData.prevMonth.dailyStock,"path-to-category":['stock', 'stockDetail', 'acquisitionType'],"path-to-date":['reportDate'],"null-zero-values":true,"dates":_vm.dates,"data-transformer":_vm.fixDailyStockDates,"display-means":true,"total-sufix":"prom./día","label-sufix":"prom./día"}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('dashboard-card',{attrs:{"title":"Valor stock actual por adquisición"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"path-to-category":['stock', 'stockDetail', 'acquisitionType'],"path-to-subcategory":['status', 'status'],"path-to-value":['stock', 'stockDetail', 'totalCost'],"is-currency":true,"display-means":true,"data-transformer":_vm.joinStockReserves}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('dashboard-card',{attrs:{"title":"Valor stock por adquisición"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.currentMonthFilters.dailyStock},
                {category: 'prevMonth', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.prevMonthFilters.dailyStock},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: currentMonthFilters.dailyStock},\n                {category: 'prevMonth', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: prevMonthFilters.dailyStock},\n              ])\n            }"}],attrs:{"records":_vm.chartData.currentMonth.dailyStock,"prev-month-records":_vm.chartData.prevMonth.dailyStock,"path-to-category":['stock', 'stockDetail', 'acquisitionType'],"path-to-date":['reportDate'],"path-to-value":['stock', 'stockDetail', 'totalCost'],"is-currency":true,"null-zero-values":true,"dates":_vm.dates,"data-transformer":_vm.fixDailyStockDates,"total-sufix":"prom./día","label-sufix":"prom./día","filter-zero-legends":false,"display-means":true}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Cantidad stock último año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'lastYear', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.lastYearFilters.dailyStock})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'lastYear', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: lastYearFilters.dailyStock})\n            }"}],attrs:{"records":_vm.chartData.lastYear.dailyStock,"prev-month-records":[],"path-to-category":['processStatus', 'status'],"path-to-date":['reportDate'],"monthly":true,"null-zero-values":true,"show-prev-month":false,"dates":_vm.lastYearDates,"data-transformer":_vm.fixDailyStockDates,"total-sufix":"prom./mes","label-sufix":"prom./mes","display-means":true}})]},proxy:true}])})],1),_c('v-col',[_c('dashboard-card',{attrs:{"title":"Valor stock último año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'lastYear', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.lastYearFilters.dailyStock})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'lastYear', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: lastYearFilters.dailyStock})\n            }"}],attrs:{"records":_vm.chartData.lastYear.dailyStock,"prev-month-records":[],"path-to-category":['processStatus', 'status'],"path-to-date":['reportDate'],"path-to-value":['stock', 'stockDetail', 'totalCost'],"monthly":true,"is-currency":true,"null-zero-values":true,"show-prev-month":false,"dates":_vm.lastYearDates,"data-transformer":_vm.fixDailyStockDates,"total-sufix":"prom./mes","label-sufix":"prom./mes","display-means":true}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Cantidad stock último año por adquisición"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'lastYear', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.lastYearFilters.dailyStock})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'lastYear', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: lastYearFilters.dailyStock})\n            }"}],attrs:{"records":_vm.chartData.lastYear.dailyStock,"prev-month-records":[],"path-to-category":['stock', 'stockDetail', 'acquisitionType'],"path-to-date":['reportDate'],"monthly":true,"null-zero-values":true,"show-prev-month":false,"dates":_vm.lastYearDates,"data-transformer":_vm.fixDailyStockDates,"total-sufix":"prom./mes","label-sufix":"prom./mes","display-means":true}})]},proxy:true}])})],1),_c('v-col',[_c('dashboard-card',{attrs:{"title":"Valor stock último año por adquisición"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'lastYear', field: 'dailyStock', queryMethod: _vm.getDailyStock, queryFilter: _vm.lastYearFilters.dailyStock})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'lastYear', field: 'dailyStock', queryMethod: getDailyStock, queryFilter: lastYearFilters.dailyStock})\n            }"}],attrs:{"records":_vm.chartData.lastYear.dailyStock,"prev-month-records":[],"path-to-category":['stock', 'stockDetail', 'acquisitionType'],"path-to-date":['reportDate'],"path-to-value":['stock', 'stockDetail', 'totalCost'],"monthly":true,"is-currency":true,"null-zero-values":true,"show-prev-month":false,"dates":_vm.lastYearDates,"data-transformer":_vm.fixDailyStockDates,"total-sufix":"prom./mes","label-sufix":"prom./mes","display-means":true}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Stock total por antigüedad"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"detail-elements-generator":_vm.getStockDetail,"filtering-method":record => record.status?.status?.name !== 'retired',"data-transformer":_vm.joinStockReserves,"criterias":_vm.stockByAgeCriterias,"show-icon":""}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Stock propio por antigüedad"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"detail-elements-generator":_vm.getStockDetail,"filtering-method":record => record.status?.status?.name !== 'retired' && record.stock.stockDetail.acquisitionType.name === 'purchase',"data-transformer":_vm.joinStockReserves,"criterias":_vm.stockByAgeCriterias}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Stock consignado por antigüedad"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"detail-elements-generator":_vm.getStockDetail,"filtering-method":record => record.status?.status?.name !== 'retired' && record.stock.stockDetail.acquisitionType.name === 'consignment',"data-transformer":_vm.joinStockReserves,"criterias":_vm.stockByAgeCriterias}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Valor stock total por antigüedad"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"detail-elements-generator":_vm.getStockDetail,"filtering-method":record => record.status?.status?.name !== 'retired',"path-to-value":['stock', 'stockDetail', 'totalCost'],"is-currency":true,"data-transformer":_vm.joinStockReserves,"criterias":_vm.stockByAgeCriterias,"show-icon":""}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Valor stock propio por antigüedad"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"detail-elements-generator":_vm.getStockDetail,"filtering-method":record => record.status?.status?.name !== 'retired' && record.stock.stockDetail.acquisitionType.name === 'purchase',"data-transformer":_vm.joinStockReserves,"path-to-value":['stock', 'stockDetail', 'totalCost'],"is-currency":true,"criterias":_vm.stockByAgeCriterias}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Valor stock consignado por antigüedad"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'stock', queryMethod: _vm.getStock, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'stock', queryMethod: getStock, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.stock,"detail-elements-generator":_vm.getStockDetail,"filtering-method":record => record.status?.status?.name !== 'retired' && record.stock.stockDetail.acquisitionType.name === 'consignment',"data-transformer":_vm.joinStockReserves,"path-to-value":['stock', 'stockDetail', 'totalCost'],"is-currency":true,"criterias":_vm.stockByAgeCriterias}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('dashboard-card',{attrs:{"title":"Stock por precio"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('cars-by-price',{directives:[{name:"intersect",rawName:"v-intersect",value:(async (entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              await _vm.submitQuery({category: 'currentMonth', field: 'stockSpecs', queryMethod: _vm.getStockSpecs, queryFilter: _vm.currentMonthFilters.stockSpecs})
            }),expression:"async (entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              await submitQuery({category: 'currentMonth', field: 'stockSpecs', queryMethod: getStockSpecs, queryFilter: currentMonthFilters.stockSpecs})\n            }"}],attrs:{"stock-specs":_vm.chartData.currentMonth.stockSpecs}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('dashboard-card',{attrs:{"title":"Stock por carrocería"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('cars-by-chassis',{directives:[{name:"intersect",rawName:"v-intersect",value:(async (entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              await _vm.submitQuery({category: 'currentMonth', field: 'stockSpecs', queryMethod: _vm.getStockSpecs, queryFilter: _vm.currentMonthFilters.stockSpecs})
              const specs = await _vm.Promise.all(_vm.chartData.currentMonth.stockSpecs.salesStockView
                .filter(item => item.status.status.name !== 'retired')
                .map(async item => {
                  return _vm.findAlternativesAttributes(item.auto)
                }))
              _vm.$set(_vm.chartData.currentMonth, 'carsByChassis', specs.map(car =>
                car.filter(spec => spec.component.slug === 'bodywork')[0]
              ))
            }),expression:"async (entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              await submitQuery({category: 'currentMonth', field: 'stockSpecs', queryMethod: getStockSpecs, queryFilter: currentMonthFilters.stockSpecs})\n              const specs = await Promise.all(chartData.currentMonth.stockSpecs.salesStockView\n                .filter(item => item.status.status.name !== 'retired')\n                .map(async item => {\n                  return findAlternativesAttributes(item.auto)\n                }))\n              $set(chartData.currentMonth, 'carsByChassis', specs.map(car =>\n                car.filter(spec => spec.component.slug === 'bodywork')[0]\n              ))\n            }"}],attrs:{"stock-specs":_vm.chartData.currentMonth.stockSpecs,"cars":_vm.chartData.currentMonth.carsByChassis}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('dashboard-card',{attrs:{"title":"Stock por combustible"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('cars-by-fuel',{directives:[{name:"intersect",rawName:"v-intersect",value:(async (entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              await _vm.submitQuery({category: 'currentMonth', field: 'stockSpecs', queryMethod: _vm.getStockSpecs, queryFilter: _vm.currentMonthFilters.stockSpecs})
              const specs = await _vm.Promise.all(_vm.chartData.currentMonth.stockSpecs.salesStockView
                .filter(item => item.status.status.name !== 'retired')
                .map(async item => {
                  return _vm.findAlternativesAttributes(item.auto)
                }))
              _vm.$set(_vm.chartData.currentMonth, 'carsByFuel', specs.map(car =>
                car.filter(spec => spec.component.slug === 'fuel')[0]
              ))
            }),expression:"async (entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              await submitQuery({category: 'currentMonth', field: 'stockSpecs', queryMethod: getStockSpecs, queryFilter: currentMonthFilters.stockSpecs})\n              const specs = await Promise.all(chartData.currentMonth.stockSpecs.salesStockView\n                .filter(item => item.status.status.name !== 'retired')\n                .map(async item => {\n                  return findAlternativesAttributes(item.auto)\n                }))\n              $set(chartData.currentMonth, 'carsByFuel', specs.map(car =>\n                car.filter(spec => spec.component.slug === 'fuel')[0]\n              ))\n            }"}],attrs:{"stock-specs":_vm.chartData.currentMonth.stockSpecs,"cars":_vm.chartData.currentMonth.carsByFuel}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('dashboard-card',{attrs:{"title":"Stock por transmisión"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('cars-by-transmission',{directives:[{name:"intersect",rawName:"v-intersect",value:(async (entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              await _vm.submitQuery({category: 'currentMonth', field: 'stockSpecs', queryMethod: _vm.getStockSpecs, queryFilter: _vm.currentMonthFilters.stockSpecs})
              const specs = await _vm.Promise.all(_vm.chartData.currentMonth.stockSpecs.salesStockView
                .filter(item => item.status.status.name !== 'retired')
                .map(async item => {
                  return _vm.findAlternativesAttributes(item.auto)
                }))
              _vm.$set(_vm.chartData.currentMonth, 'carsByTransmission', specs.map(car =>
                car.filter(spec => spec.component.slug === 'transmission')[0]
              ))
            }),expression:"async (entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              await submitQuery({category: 'currentMonth', field: 'stockSpecs', queryMethod: getStockSpecs, queryFilter: currentMonthFilters.stockSpecs})\n              const specs = await Promise.all(chartData.currentMonth.stockSpecs.salesStockView\n                .filter(item => item.status.status.name !== 'retired')\n                .map(async item => {\n                  return findAlternativesAttributes(item.auto)\n                }))\n              $set(chartData.currentMonth, 'carsByTransmission', specs.map(car =>\n                car.filter(spec => spec.component.slug === 'transmission')[0]\n              ))\n            }"}],attrs:{"stock-specs":_vm.chartData.currentMonth.stockSpecs,"cars":_vm.chartData.currentMonth.carsByTransmission}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }