
  import { Component, Prop } from 'vue-property-decorator'
  import SupervisorDashboard from './SupervisorDashboard'
  import { acquisitionTypeColors } from '@/utils/acquisitionTypeColors'
  import dayjs from 'dayjs'
  import { utcToLocal, fixPrice, parseToNumber } from '@/utils/general'

@Component
  export default class SupervisorStock extends SupervisorDashboard {
  @Prop({ default: true, type: Boolean }) showGeneralResults
  Promise = Promise
  dayjs = dayjs
  utcToLocal = utcToLocal
  fixPrice = fixPrice

  stockByAgeCriterias = [
    {
      name: 'Hasta 1 semana',
      color: '#13CE66',
      filter: record => {
        const dates = this.generateWeeksLimit(1)
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && (dates.upperLimit.isAfter(recordDate, 'day') || dates.upperLimit.isSame(recordDate, 'day'))
      },
    },
    {
      name: 'Hasta 2 semanas',
      color: '#7D67BC',
      filter: record => {
        const dates = this.generateWeeksLimit(2)
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && dates.upperLimit.isAfter(recordDate, 'day')
      },
    },
    {
      name: 'Hasta 3 semanas',
      color: '#6185DB',
      filter: record => {
        const dates = this.generateWeeksLimit(3)
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && dates.upperLimit.isAfter(recordDate, 'day')
      },
    },
    {
      name: 'Hasta 1 mes',
      color: '#FAD901',
      filter: record => {
        const dates = this.generateWeeksLimit(4)
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && dates.upperLimit.isAfter(recordDate, 'day')
      },
    },
    {
      name: 'Hasta 2 meses',
      color: '#FF9E2C',
      filter: record => {
        const dates = this.generateWeeksLimit(2, 'month')
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && dates.upperLimit.isAfter(recordDate, 'day')
      },
    },
    {
      name: 'Hasta 3 meses',
      color: '#30426E',
      filter: record => {
        const dates = this.generateWeeksLimit(3, 'month')
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && dates.upperLimit.isAfter(recordDate, 'day')
      },
    },
    {
      name: 'Más de 3 meses',
      color: '#F03738',
      filter: record => {
        const dates = this.generateWeeksLimit(3, 'month')
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return dates.lowerLimit.isAfter(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')
      },
    },
  ]

  // Methods
  beforeCreate () {
    this.getData = async () => {
      this.$set(this.chartData.currentMonth, 'stock', await this.getStock(this.currentMonthFilters.stockSpecs))
    }
  }

  generateWeeksLimit (count: number, type: 'week' | 'month' = 'week') {
    const currentDate = dayjs().endOf('day')
    const multiplier = type === 'month' ? 4 : 1
    const upperLimit = currentDate.subtract((count - 1) * multiplier, 'week')
    const lowerLimit = currentDate.subtract(count * multiplier, 'week')

    return {
      lowerLimit,
      upperLimit,
    }
  }

  getStockDetail (record) {
    const { auto, stock } = record
    const brand = auto.version.version.model.brand.name
    const model = auto.version.version.model.name
    const version = auto.version.version.name
    const year = auto.version.year.id

    const carModel = `${brand} ${model} ${version} ${year}`
    const carPlate = auto.registrationPlate

    const currentDate = dayjs()
    const daysInStock = currentDate.endOf('day').diff(utcToLocal(record.stock.createdAt), 'days')

    const price = stock.prices?.[0]?.amount || stock.stockDetail.expectedPublicationAmount || stock.stockDetail.appraisalAmount || 0
    const cost = stock.stockDetail.totalCost

    const status = record.status.status.description

    return [
      { title: carModel, value: carPlate, bold: true, icon: record.stock.stockDetail.acquisitionType.name === 'consignment' ? { name: 'mdi-cart-outline', color: '#FF9E2C' } : undefined },
      { title: 'Estado', value: status, bold: true },
      { title: 'Días en stock', value: daysInStock },
      { title: 'Costo total', value: fixPrice(cost) },
      { title: stock.prices?.[0]?.amount ? 'Precio de lista' : 'Precio esperado de publicación', value: fixPrice(price) },
    ]
  }

  async getGeneration (version, year) {
    return this.fetchData({
      query: { name: 'findLite', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: version }, id_year: { _eq: year } } },
      force: true,
    })
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    const getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    const generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      return generation?.attributes
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        _and: [
          { id_version_year: { _eq: auto.version.id } },
          { component: { slug: { _in: ['transmission', 'bodywork', 'fuel'] } } },
        ],
      },
      force: true,
    })

    if (attributes?.length) {
      return attributes
    }
  }

  async fixDailyStockDates (records) {
    records = records.map(record => {
      record.reportDate = record.reportDate.split('T')[0] + 'T12:00:00'
      return record
    })
    records = await this.joinStockStatusReserves(records)
    return records
  }

  async joinStockStatusReserves (records: Record<string, any>[]): Promise<Record<string, any>> {
    if (!records) return
    const statusesToRemove = ['retired', 'sold']
    records = records.filter(record => {
      if (record.processStatus) {
        return !statusesToRemove.includes(record.processStatus.status.name)
      } else {
        return !statusesToRemove.includes(record.status.name)
      }
    })
    const reserveStatus = (await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        status: {
          name: { _eq: 'reserved' },
        },
      },
    }))[0].status
    for (let i = 0; i < records.length; i++) {
      if (records[i].processStatus) {
        if (records[i].processStatus.status.name === 'reservation_process') {
          records[i].processStatus.status = reserveStatus
        }
      } else if (records[i].status.name === 'reservation_process') {
        records[i].status = reserveStatus
      }
      const status = records[i].status
      if (status) {
        if (status?.name === 'enabling') {
          status.order = 1
        } else if (status?.name === 'available') {
          status.order = 3
        } else if (status?.name === 'reserved') {
          status.order = 5
        } else if (status?.name === 'selling') {
          status.order = 7
        }
        records[i].status = status
      }
    }
    return this.setAcquisitionTypeColors(records)
  }

  async calculateTransfer (id) {
    if (!id) return
    const processAuto = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'auto' } },
    })
    const { municipalityTax, fixedTransferCosts } = processAuto[0].config

    const stock = await this.fetchData({
      query: { name: 'findLite', model: 'Stock' },
      filter: { id: { _eq: id } },
    })

    const stockDetail = await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { id_stock: { _eq: id } },
    })

    const { expectedPublicationAmount } = stockDetail[0]
    const { price } = stock[0]
    const { auto } = stockDetail[0]

    let automotiveRegistrationCost = 0
    const year = Number(dayjs().format('YYYY'))

    if (auto?.generation?.id) {
      const filter = { _and: [{ id_generation: { _eq: auto.generation.id } }, { year: { _eq: year } }] }
      const registration = await this.fetchData({
        query: { name: 'find', model: 'AutomotiveRegistration' },
        filter,
      })
      automotiveRegistrationCost = registration?.[0]?.appraisal
    }

    let baseValue

    if (price?.amount && automotiveRegistrationCost) {
      baseValue = Math.max(price.amount, automotiveRegistrationCost)
    } else if (price?.amount) {
      baseValue = price.amount
    } else if (automotiveRegistrationCost && expectedPublicationAmount) {
      baseValue = Math.max(automotiveRegistrationCost, expectedPublicationAmount)
    } else if (expectedPublicationAmount) {
      baseValue = expectedPublicationAmount
    } else {
      return 0
    }

    return Math.round(parseToNumber((baseValue * municipalityTax) + fixedTransferCosts))
  }

  async setProjectedUtility (records) {
    records = await Promise.all(records.map(async record => {
      let projectedUtility = 0
      const salePrice = record.stock.prices?.[0]
      const salePriceAmount = salePrice?.amount

      const { enablementCost } = record
      const { totalCost, expectedPublicationAmount } = record.stock.stockDetail

      if (salePriceAmount) {
        const discount = salePrice?.margin || 0
        const bonus = salePrice?.bonus?.sort((prev, next) => next - prev)?.[0]?.amount || 0
        projectedUtility = salePrice.amount - totalCost - enablementCost - (discount > bonus ? discount : bonus)
      } else if (expectedPublicationAmount) {
        const transferCost = await this.calculateTransfer(record.stock.id)
        projectedUtility = expectedPublicationAmount - (totalCost || 0) - (enablementCost || 0) - (transferCost || 0) * 1.5
      }

      return {
        ...record,
        projectedUtility,
      }
    }))
    return this.joinStockReserves(records)
  }

  async joinStockReserves (records: Record<string, any>[]): Promise<Record<string, any>> {
    if (!records) return
    const statusesToRemove = ['retired', 'sold']
    records = records.filter(record => {
      return !statusesToRemove.includes(record?.status?.status?.name)
    })
    const reserveStatus = (await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        status: {
          name: { _eq: 'reserved' },
        },
      },
    }))[0].status
    for (let i = 0; i < records.length; i++) {
      if (records[i].status?.status?.name === 'reservation_process') {
        records[i].status.status = reserveStatus
      }
      const status = records[i].status?.status
      if (status) {
        if (status?.name === 'enabling') {
          status.order = 1
        } else if (status?.name === 'available') {
          status.order = 3
        } else if (status?.name === 'reserved') {
          status.order = 5
        } else if (status?.name === 'selling') {
          status.order = 7
        }
        records[i].status.status = status
      }
    }
    return this.setAcquisitionTypeColors(records)
  }

  setAcquisitionTypeColors (records) {
    return records.map(record => {
      const acquisitionType = record.stock.stockDetail.acquisitionType
      if (!acquisitionType) return
      acquisitionType.color = acquisitionTypeColors[acquisitionType?.name]
      if (acquisitionType.name === 'purchase') {
        acquisitionType.description = 'Propio'
      }
      record.stock.stockDetail.acquisitionType = acquisitionType
      return record
    })
  }

  // Getters

  // Watchers
  }
